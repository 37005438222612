<template>
  <div>
    <common-header></common-header>
    <div class="statistics">
      <div class="statis-box" v-if="census">
        <table>
          <tr>
            <th></th>
            <th>人数</th>
            <th>订单数</th>
            <th>订单额</th>
          </tr>
          <tr>
            <td>总数</td>
            <td class="canClick" @click="changeCondition(0)">{{census.total.userCount}}</td>
            <td class="canClick" @click="$router.push('/account/tglevelorder')">{{census.total.orderCount}}</td>
            <td>{{(census.total.orderAmount).toFixed(2)}}</td>
          </tr>
          <tr>
            <td>月增</td>
            <td class="canClick" @click="changeCondition(1)">{{census.month.userCount}}</td>
            <td class="canClick" @click="$router.push({path: '/account/tglevelorder', query: {curMonthStartStr: census.curMonthStartStr, curMonthEndStr: census.curMonthEndStr}})">{{census.month.orderCount}}</td>
            <td>{{(census.month.orderAmount).toFixed(2)}}</td>
          </tr>
          <tr>
            <td>日增</td>
            <td class="canClick" @click="changeCondition(2)">{{census.day.userCount}}</td>
            <td class="canClick" @click="$router.push({path: '/account/tglevelorder', query: {curMonthStartStr: census.curDayStr, curMonthEndStr: census.curDayStr}})">{{census.day.orderCount}}</td>
            <td class="canClick" @click="$router.push({path: '/account/tgorder'})">{{(census.day.orderAmount).toFixed(2)}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="warp">
      <div class="selection-box">
        <div class="item"  @click.stop="openPicker()">
          <input type="text" readonly placeholder="注册日期起" :value="startTime">
          <div class="clear" v-show="startTime != ''" @click.stop="clearClick('startTime')"></div>
          <i class="icon-black-arrow"></i>
        </div>
        <div class="item" @click.stop="openPicker2()">
          <input type="text"   readonly placeholder="注册日期止" :value="endTime">
          <div class="clear" v-show="endTime != ''" @click.stop="clearClick('endTime')"></div>
          <i class="icon-black-arrow"></i>
        </div>
      </div>
      <div class="selection-box">
        <div class="item">
          <input type="text"  placeholder="姓名、店铺" v-model="searchName">
        </div>
        <div class="item">
          <input type="number"  placeholder="手机号码" v-model="searchPhone">
        </div>
        <div class="item search-box" @click="searchClick">
          查询
        </div>
      </div>
    </div>



    <ul class="lists"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="loading"
        infinite-scroll-immediate-check="false"
        infinite-scroll-distance="200">
          <li class="item" v-for="(item, index) in list" :key="index" >
            <div class="item-box" @click="$router.push({path: '/account/tgorder', query: {userId: item.id}})">
              <img class="user-pic" :src="item.wxImg" alt="">
              <div class="user-box">
                <div class="top">
                  <img class="level" :src="requestImgUrl(item.icon)" alt="">
                  <div class="user-name">{{item.userNickName}}</div>
                </div>
                <div class="bottom">{{item.registTime}}</div>
              </div>
              <div class="order-info">
                <div class=""><span>订单数:</span> {{item.orderCount}}</div>
                <div class=""><span>订单额:</span> {{item.orderAmount}}</div>
              </div>
            </div>
            <div class="sub-bottom">
              <a v-if="(item.receivePhone && item.receivePhone != '') || (item.storeName && item.storeName != '') " :href="`tel:${item.receivePhone}`"  class="shop">{{item.storeName && item.storeName != '' ? (item.storeName+':') : ''}}{{item.receivePhone}}</a>
              <a v-else></a>
              <div class="gift-balance" @click="chongzhiClick(item.id)">送优惠</div>
            </div>
          </li>
        </ul>
    
    <no-data v-if="nodata"></no-data>

    <mt-popup :closeOnClickModal="isClickHide" class="w100" v-model="chongzhi" position="center">
      <div class="mint-msgbox">
        <div class="mint-msgbox-content">
          <div class="mint-msgbox-message" style="color: #333">优惠金额</div>
          <div class="mint-msgbox-input">
            <input style="text-align:center" placeholder="请输入金额" type="number" inputmode="decimal" v-model="chongzhiMN" />
            <div class="mint-msgbox-errormsg" v-if="errormsg">请输入金额</div>
          </div>
        </div>
        <div class="mint-msgbox-btns">
          <button class="mint-msgbox-btn mint-msgbox-cancel " style="" @click="chongzhi = false; chongzhiMN = ''">
            取消
          </button>
          <button class="mint-msgbox-btn mint-msgbox-confirm " @click="recharge">确定</button>
        </div>
      </div>
    </mt-popup>

    <mt-datetime-picker
      ref="picker"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      :endDate="endDate"
      @confirm="handleConfirm"
      v-model="xzStartTime"
    >
    </mt-datetime-picker>

    <mt-datetime-picker
      ref="picker2"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      class="time2"
      :endDate="endDate"
      @confirm="handleConfirm2"
      v-model="xzEndTime"
    >
    </mt-datetime-picker>

  </div>
</template>
<script>
import { mapActions } from 'vuex';
import {
  requestImgUrl,
  CountDown,
  isWeiXin,
  isUrlParam,
  DateFormat,
} from "@/utils/common";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    return {
      startTime: "",
      endTime: "",
      endDate: new Date(),
      timeStartValue: new Date(),
      xzStartTime: new Date(),
      xzEndTime: new Date(),
      chongzhi: false,
      isClickHide: false,
      chongzhiMN: "",
      searchName: "",
      searchPhone: "",
      da: '',
      loading: false,
      list: [],
      pages: 1,
      pageNum: 1,
      nomore: false,
      nodata:false,
      // 推广统计
      census: '',
      errormsg: false,
      userId: "",
      keep: true,
    }
  },
  activated() {
    this.keep = true;
    this.promotionSort().then((res)=> {
      this.census = res.data;
    }).catch((e)=> {
      if (e.status == 400) {
        setTimeout(()=> {
          this.$router.go(-1);
        },2000)
      }
    })
  },
  mounted() {
    this.init();
  },
  deactivated() {
    this.keep = false;
  },
  methods: {
    changeCondition(i) {
      this.searchName = "";
      this.searchPhone = "";
      this.loading = false;
      if (i == 0) {
        this.startTime = "";
        this.endTime = "";
        this.init();
      } else if (i == 1) {
        this.startTime = this.census.curMonthStartStr;
        this.endTime = this.census.curMonthEndStr;
        this.init();
      } else if (i == 2) {
        this.startTime = this.census.curDayStr;
        this.endTime = this.census.curDayStr;
        this.init();
      }
    },
    clearClick(e) {
      this[e] = "";
    },
    openPicker(i) {
      this.$refs.picker.open();
    },
    openPicker2(i) {
      this.$refs.picker2.open();
    },
    handleConfirm(e) {
      this.startTime = DateFormat(e).substring(0, 10);
    },
    handleConfirm2(e) {
      this.endTime = DateFormat(e).substring(0, 10);
    },
    chongzhiClick(id) {
      this.userId = id;
      this.chongzhi = true;
    },
    // 充值
    recharge() {
      if (this.chongzhiMN > 0 ) {
        this.postPromotedBalance({
          amount: this.chongzhiMN,
          userId: this.userId,
        }).then((res)=> {
          this.$toast("赠送成功,请用户查看余额");
          this.chongzhi = false;
          this.chongzhiMN = "";
          this.errormsg = false;
        }).catch(()=> {
          this.chongzhi = false;
        })
      } else {
        this.errormsg = true;
      }
    },
    searchClick() {
      this.loading = false;
      this.init();
    },
    // 显示加载提示
    showLoadTxt(type) {
      if (type == 'show') {
        this.$Indicator.open({
          text: "加载中...",
          spinnerType: "snake"
        });
      } else {
        this.$Indicator.close();
      }
    },
    init(pageNum, add) {
      if(!pageNum){
        pageNum = 1;
      }
      this.showLoadTxt('show');
      this.promotion({
        startDate: this.startTime,
        endDate: this.endTime,
        userName: this.searchName,
        phone: this.searchPhone,
        pageNum: pageNum || 1,
      }).then((res)=> {
        this.pageNum = pageNum;
        if (add) {
          this.list = this.list.concat(res.data.list);
          this.loading = false;
        } else {
          this.list = res.data.list;
          this.nodata = this.list.length > 0 ? false :true;
          this.pages = res.data.pages;
        }
        this.showLoadTxt('hide');
      })
    },
 
    // 加载分页
    loadMore() {
      if (!this.keep) return false;
      this.loading = true;
      let {pageNum, pages} = this;
      pageNum = pageNum + 1;
      if (pageNum > pages) {
        this.nomore = true;
      } else {
        this.init(pageNum, 'add');
      }
    },
    ...mapActions('account', [
      'promotion',
      'promotionSort',
      "postPromotedBalance",
    ])
  },
}
</script>
<style lang='less' scoped>
.clear{
  width: .4rem;
  height: .4rem;
  border-radius: 50%;
  color: #333;
  margin-left: .2rem;
  margin-right: .4rem;
  background-image: url('../../assets/images/img/clear-input.png');
  background-size: 100% 100%;
}
.sub-bottom{
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-top: .1rem;
}
.gift-balance{
  padding: .05rem .1rem;
  font-size: .24rem;
  text-align: center;
  color: #fff;
  border-radius: .15rem;
  background: rgba(255,113,65,1);/* Old Browsers */
	background: -moz-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,113,65,1)), color-stop(100%, rgba(255,185,139,1)));/* Chrome, Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* IE 10+ */
	background: linear-gradient(to right, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%);/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7141', endColorstr='#ffb98b', GradientType=1 );/* IE6-9 */
}
.selection-box .item.search-box{
  flex: .4;
  color: #fff;
  justify-content: center !important;
  background: rgba(255,113,65,1);/* Old Browsers */
	background: -moz-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,113,65,1)), color-stop(100%, rgba(255,185,139,1)));/* Chrome, Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* IE 10+ */
	background: linear-gradient(to right, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%);/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7141', endColorstr='#ffb98b', GradientType=1 );/* IE6-9 */
}
.selection-box {
  display: flex;
  align-items: center;
  margin-bottom: .2rem;
  input{
    font-size: .3rem;
  }
  .item {
    height: .8rem;
    background: #fff;
    border: 1px solid #f5f5f5;
    padding: 0.2rem;
    flex: 1;
    margin-right: 0.2rem;
    border-radius: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    input{
      flex: 1;
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.statistics{
  padding: .2rem;
  color: #333;
  .statis-box{
    background: #fff;
    padding: .2rem;
    border-radius: .25rem;
    table{
      font-size: .28rem;
      width: 100%;
      text-align: center;
      th{
        color: #cacaca;
        font-weight: normal;
      }
      td{
        &:first-child{
          color: #cacaca;
        }
        padding: .1rem 0;
      }
    }
    .canClick{
      font-weight: bolder;
    }
  }
}
.lists{
  padding: 0 .2rem .2rem;
  .shop{
    font-size: 0.24rem;
    color: #cacaca;
    padding-left: 1.2rem;
  }
.item{
    background: #fff;
    padding: .2rem;
    border-radius: .25rem;

    margin-bottom: .2rem;
    .item-box{
      display: flex;
      align-items: center;
    }
    .order-info{
      font-size: .26rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: .1rem 0;
      span{
        color: #cacaca;
      }
    }
    .user-box{
      margin-left: .2rem;
      padding: .1rem 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 1rem;
      .top{
        display: flex;
        .user-name{
          margin-left: .15rem;
        }
      }
      .bottom{
        color: #cacaca;
        font-size: .24rem;
      }
    }
    .level{
      width: .4rem;
      height: .4rem;
      border-radius: 50%;
    }
    .user-pic{
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
  }
}
  
</style>